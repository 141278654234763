<!--
 * @Date: 2023-03-13 16:11:34
 * @LastEditTime: 2023-07-05 09:36:19
 * @Description: 
 * @FilePath: \szlsnk-user-mp\src\views\static\hospitalIntroduction.vue
-->
<template>
  <div>
    <div class="title">
      <img src="../../assets/img/blue-shi.png" alt="">
      <div class="title-txt">
        深圳蓝生脑科医院简介
      </div>
      <img src="../../assets/img/blue-shi.png" alt="">
    </div>
    <div class="content">
      <img src="../../assets/img/hosp.png" alt="" class="hospImg">
      <p class="text">
        <span class="bold">深圳蓝生脑科医院 </span>
        是深圳市卫健委批准设立的三级脑科医院，集医疗、教学、科研、康复于一体，致力于创建现代化、国际化、专业化的脑科医院和知名院校附属医院。
      </p>
      <p class="text">
        医院坐落于深圳市龙华区中心位置，龙观大道与工业路交叉口，地理优越，交通便利，建筑面积3万余平方米，可开放床位近600张。拥有最新型飞利浦3.0T MRI128排CT、双C臂大平板DSA、神经导航系统、蔡司显微镜、康复机器人、高压氧舱等高端医疗设备和VIP病房、ICU、百级层流手术室、康复大厅等先进诊疗设施，为高品质诊疗服务提供可靠保障。
      </p>
      <p class="text">
        医院秉承“大业之本，首在英才”的人才理念，积极引进了在国家省、市各医学专业领域担任主任 (副) 委员且造诣深厚、经验丰富的名医名家，组建医德高尚、医术精湛、医风严谨的专业团队，持续提升医疗质量、诊疗水平和服务品质。
      </p>
      <p class="text">
        医院坚持“大专科、强综合”的发展模式，打造神经外科、神经内科重症医学科、康复医学科、临床心理科五大重点学科，开设心血管内科呼吸内科、消化内科、内分泌科、普外科、骨科六大优势学科及中医科肿瘤科、高压氧等特色学科，构建特色鲜明、结构合理、潜力厚实的学科体系，引领推动医院高质量、内涵式、可持续发展。
      </p>
      <div class="row">
        <img src="../../assets/img/address.png" alt="">
        <span class="bold size28 dark-color">医院地址：</span>
      </div>
      <p class="bold size28 dark-color">
        广东省深圳市龙华区龙观大道360号
      </p>
    </div>
  </div>
</template>
<script>
export default {

}
</script>
<style scoped lang="less">
.title{
  width: 500px;
  margin: 32px auto;
  display: flex;
  img{
    width:32px;
    height: 32px;
    position: relative;
    top: 9px;
  }
  .title-txt{
    font-size: 36px;
    font-weight: bold;
    color: #3D3D3D;
    margin: 0 16px;
  }
}
.content{
  padding: 0 32px 32px;
  .hospImg{
    width: 686px;
    height: 358px;
  }
  .text{
    text-indent: 60px;
    font-size: 28px;
    color: #3D3D3D;
    line-height: 46px;
  }
  .row{
    display: flex;
    margin-top: 24px;
    img{
      width: 30px;
      height: 30px;
      margin-right: 12px;
      margin-top: 3px;
    }
  }
}
</style>
